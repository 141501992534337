import React from "react"
import { Row, Col, Container, ResponsiveEmbed } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO title="Home" keywords={[`San Marcos`, `auto`, `body`, `shop`, `paint`,  `San Marcos Auto Body Shop`]} />

    <section className="d-flex flex-column align-items-center justify-content-center py-4 bg-white">
      <figure class="w-75 mb-4">
        <img className="w-100" src="/images/big-boys-collision-truck--blue.png"></img>
      </figure>
    </section>

    <Container className="">

      <Row id="section-information" className="min-vh-100 align-items-center py-2 text-uppercase">

        <Col sm="6" className="text-center">
          <h4 class="text-uppercase mb-1">Collision and Damage Repair</h4>
          <h4 class="text-uppercase mb-1">Insurance Claim Estimates</h4>
          <h4 class="text-uppercase mb-1">Paintless Dent Removal</h4>
          <h4 class="text-uppercase mb-1">Deductible Assistance</h4>
          <h4 class="text-uppercase">Pick Up and Delivery</h4>
        </Col>

        <Col sm="6" className="text-center">
          <a
            href="mailto:chuy.bigboys@yahoo.com"
            target="_blank"
            className="text-white"
            title="Email"
          >
            <h5 className="">Chuy Maldonado</h5>
          </a>
          <p className="">
            Manager
          </p>
        </Col>

        <Col sm="12" className="text-center pt-4">
          <figure class="">
            <iframe width="320" height="180" src="https://www.youtube.com/embed/RRocUI3TEbw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </figure>
        </Col>

      </Row>

    </Container>


    <section id="section-reviews" className="bg-white text-dark">

      <Container className="">

        <Row className="min-vh-100 align-items-center py-2 text-uppercase">

          <Col xs="12" className="text-center mb-2">
            <h2>
              Customer Reviews
            </h2>
          </Col>

          <Col sm="6" className="text-center mb-2">
            <h5>
              &ldquo;I highly recommend Big Boys Collision. I just picked up my ride and it's perfect! Anyone out there that needs vehicle repair with good and honest prices, I'm telling you this is the place. Thanks, Big Boys Collision!&rdquo;
            </h5>
          </Col>

          <Col sm="6" className="text-center mb-2">
            <h5>
              &ldquo;I moved from New York to Texas and on my trip experienced some damage to my gas tank from an object that kicked up from a truck in front of me.  Chuy and his team went above and beyond to fix my vehicle.  I also would like to point out their superior customer service.  I highly recommend Big Boys Collision for any repairs.&rdquo;
            </h5>
          </Col>

          <Col sm="6" className="text-center mb-2">
            <h5>
              &ldquo;Awesome work great shop. Prices are in point work is even better.  The body shop took care of vehicle. Can't go wrong.&rdquo;
            </h5>
          </Col>

          <Col sm="6" className="text-center mb-2">
            <h5>
              &ldquo;Chuy and his guys did a great job on our car. Great attention to detail and very professional.&rdquo;
            </h5>
          </Col>

          <Col sm="6" className="text-center mb-2">
            <h5>
              &ldquo;Chuy and crew do excellent work, all while being helpful and honest. Excellent service from this local small business!&rdquo;
            </h5>
          </Col>

          <Col sm="6" className="text-center mb-2">
            <h5>
              &ldquo;Five out of five stars!&rdquo;
            </h5>
          </Col>

        </Row>

      </Container>

    </section>

    <section id="section-location" className="">
      <figure className="google-map">
        <ResponsiveEmbed aspectRatio="16by9">
          <iframe
            title="Google Map"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3462.055303656428!2d-97.945706!3d29.8049466!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x865caf42877a854f%3A0x2ff863e950f58551!2s5040%20TX-123%20bldg%20400%2C%20San%20Marcos%2C%20TX%2078666!5e0!3m2!1sen!2sus!4v1628204702123!5m2!1sen!2sus"
            width="960"
            height="540"
            frameborder="0"
            allowfullscreen=""
            aria-hidden="false"
            tabindex="0"
          ></iframe>
        </ResponsiveEmbed>
      </figure>
    </section>

    <section id="section-gallery" className="">

      <main className="">

        <Row className="no-gutters">

          <Col sm="6" className="">
            <figure class="">
              <img className="gallery__image w-100" src="/images/gallery/20211011-01-01.jpg"></img>
            </figure>
          </Col>

          <Col sm="6" className="">
          <figure class="">
              <img className="gallery__image w-100" src="/images/gallery/20211011-01-02.jpg"></img>
            </figure>
          </Col>

          <Col sm="6" className="">
          <figure class="">
              <img className="gallery__image w-100" src="/images/gallery/20211011-02-01.jpg"></img>
            </figure>
          </Col>

          <Col sm="6" className="">
          <figure class="">
              <img className="gallery__image w-100" src="/images/gallery/20211011-02-02.jpg"></img>
            </figure>
          </Col>

          <Col sm="6" className="">
          <figure class="">
              <img className="gallery__image w-100" src="/images/gallery/20211011-03-01.jpg"></img>
            </figure>
          </Col>

          <Col sm="6" className="">
          <figure class="">
              <img className="gallery__image w-100" src="/images/gallery/20211011-03-02.jpg"></img>
            </figure>
          </Col>

          <Col sm="6" className="">
          <figure class="">
              <img className="gallery__image w-100" src="/images/gallery/20211011-04-01.jpg"></img>
            </figure>
          </Col>

          <Col sm="6" className="">
          <figure class="">
              <img className="gallery__image w-100" src="/images/gallery/20211011-04-02.jpg"></img>
            </figure>
          </Col>

          <Col sm="6" className="">
          <figure class="">
              <img className="gallery__image w-100" src="/images/gallery/20211027-01-01.jpg"></img>
            </figure>
          </Col>

          <Col sm="6" className="">
          <figure class="">
              <img className="gallery__image w-100" src="/images/gallery/20211027-01-02.jpg"></img>
            </figure>
          </Col>

          <Col sm="12" className="">
          <figure class="">
              <img className="gallery__image w-100" src="/images/gallery/20211027-02-01.jpg"></img>
            </figure>
          </Col>

        </Row>

      </main>

    </section>

  </Layout>
)

export default IndexPage
